.test-container {
    display: grid;
    grid-template-rows: 1fr;
    //grid-template-rows: min-content 1fr;
    overflow: hidden;
    width: 100%;
    position: relative;
}

.document__container {
    border: 1px solid grey;
}

.sticky {
    position: sticky;
    //width: 100%;
    top: 10px;
    z-index: 999;
}

.reviews {
    position: relative;
    display: grid;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }
}

.reviews__container {
    display: grid;
    position: absolute;
}

.review {
    background-color: rgba(255,255,255,0);
    border-radius: 10px 10px 10px 10px;
    border: 2px solid #f5f5f5;
    padding: 25px 15px;
    text-align: left;
    z-index: 0;

    overflow: hidden;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

    //&::before {
    //    content: '';
    //    position: sticky;
    //    top: 2px;
    //    left: 0;
    //    width: 100%;
    //    height: 2px;
    //    background-color: #f5f5f5;
    //    z-index: 2;
    //    //transform: translateY(-100%);
    //}

    &::after {
        content: '';
        position: fixed;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #f5f5f5;
        z-index: 2;
        transform: translateY(-100%);
    }

    &__header {
        > div:first-child {
            display: flex;
            justify-content: space-between;
        }

        &--name {
            font-weight: 500;
        }

        &--stars {
            display: grid;
            grid-template-columns: repeat(5, min-content);
            margin-bottom: .75rem;

            svg {
                max-height: 24px;
                max-width: 24px;
            }

            //img, svg {
            //    width: 118px;
            //    height: 29px;
            //    max-height: 29px;
            //    aspect-ratio: 118 / 29;
            //}
        }

        &--pfp {
            img {
                max-height: 45px;
                //width: 45px;
            }
        }

        &--google {
            img, svg {
                max-height: 30px;
                max-width: 30px;
                height: 30px;
                width: 30px;
                border-radius: 15px;
            }
        }
    }
}

.service-description {
    padding: var(--default-font-padding);

    background-color: #edf2f9;
    border: var(--default-border);
    border-radius: var(--default-border-radius);
}

.accounting-softwares {
    display: grid;
    grid-template-columns: 340px 1fr;
    grid-gap: 2rem;

    > div {
        display: grid;
        place-content: center;

        padding: 1rem 4.5rem;

        border-radius: 10px;
        border: 2px #F5F5F5 solid;
    }

    img {
        max-width: 100%;
    }

    @media only screen and (max-width: 1200px) {
        grid-template-columns: 240px 1fr;

        > div {
            padding: 1rem 2.5rem;
        }
    }

    @media only screen and (max-width: 900px) {
        grid-template-columns: 200px 1fr;
        grid-gap: 1rem;
    }

    @media only screen and (max-width: 750px) {
        grid-template-columns: 1fr;
        grid-template-rows: 72px 1fr;

        img {
            max-height: 40px;
        }
    }
}

.svg {
    &--flip {
        transform: rotateY(180deg);
        padding: .75rem 1.5rem;
    }
}
